<template>
  <BaseDialog v-model="open" size-class="max-w-6xl h-[86vh]">
    <template #header>Dataset GPT</template>
    <template #default>
      <div class="flex flex-1 flex-col gap-4">
        <form class="flex items-center gap-2" @submit="fetchSteps">
          <BaseSearchbox class="flex-1" v-model="state.query" placeholder="Enter your query" />
          <BaseButton :disabled="state.loading" size="sm" @press="fetchSteps">Fetch Steps</BaseButton>
        </form>

        <div v-if="state.steps.length" class="flex flex-col gap-4">
          <div class="grid grid-cols-12 items-center gap-2">
            <div class="col-span-8 col-start-2 font-bold">Task</div>
            <div class="col-span-3 font-bold">Table</div>
            <template v-for="(step, i) in state.steps" :key="i">
              <div class="col-span-1 flex items-center gap-1">
                {{ i + 1 }}.
                <BaseButton size="xxs" type="ghost" @press="deleteStep(i)">
                  <XMarkIcon class="h-3.5 w-3.5" />
                </BaseButton>
                <BaseButton size="xxs" type="ghost" @press="addStep(i)">
                  <PlusIcon class="h-3.5 w-3.5" />
                </BaseButton>
              </div>
              <BaseTextbox class="col-span-8 flex-1" v-model="step.task" />
              <div class="col-span-3 flex gap-1">
                <BaseSelect
                  v-model="step.table"
                  :options="datasets"
                  searchable
                  portal
                  placeholder="Dataset Table"
                  dropdown-width-class="w-80"
                />
                <BaseButton size="xxs" type="ghost" @press="step.table = null">
                  <XMarkIcon class="h-3.5 w-3.5" />
                </BaseButton>
              </div>
            </template>
            <div class="col-span-12 flex justify-end">
              <BaseButton size="sm" type="secondary" @press="showNotesModal = true">
                <DocumentTextIcon class="h-5 w-5 text-gray-300" />
              </BaseButton>
            </div>
          </div>
          <div class="ml-auto flex items-center gap-2">
            <BaseButton :disabled="state.loading" size="sm" @press="processSteps"> Process Steps </BaseButton>
          </div>
        </div>

        <div v-if="state.script" class="flex w-full flex-1 gap-4">
          <div class="flex flex-1 flex-col gap-1">
            <p class="text-xs leading-5 text-gray-400">Python Script</p>
            <CodeEditor language="python" v-model="state.script" />
            <div class="flex items-center gap-2">
              <BaseTextbox class="flex-1" v-model="state.feedback" placeholder="Enter your feedback" />
              <BaseButton :disabled="state.loading" size="sm" @press="refactorScript">Refactor Script</BaseButton>
            </div>
          </div>
          <div class="flex flex-1 flex-col gap-1">
            <p class="text-xs leading-5 text-gray-400">JSON Output</p>
            <CodeEditor :model-value="state.scriptResults || {}" stringify-code read-only language="json" />
            <div class="flex items-center justify-end gap-2">
              <p v-if="state.error" class="text-xs text-red-500">{{ state.error.slice(0, 150) }}</p>
              <BaseButton :disabled="state.loading" size="sm" @press="processScript">Send Request</BaseButton>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <BaseButton class="ml-auto" size="sm" type="secondary" @press="reset"> Reset </BaseButton>
      <BaseButton :disabled="!canSubmitTrainingData" size="sm" type="secondary" @press="addToTrainingData">
        Add to Training Data
      </BaseButton>
      <BaseButton size="sm" @press="openComponentBuilder">
        Open in Component Builder <ArrowUpRightIcon class="ml-1 h-3.5 w-3.5" />
      </BaseButton>
    </template>
  </BaseDialog>
  <DatasetGptNotesModal v-model="showNotesModal" />
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useSessionStorage } from '@vueuse/core';
import { XMarkIcon, ArrowUpRightIcon, DocumentTextIcon, PlusIcon } from '@heroicons/vue/20/solid';
import CodeEditor from '@/components/CodeEditor.vue';
import { useStore } from 'vuex';
import DatasetGptNotesModal from './DatasetGptNotesModal.vue';

const showNotesModal = ref(false);

const $store = useStore();
import useHttp from '@/composeables/http';

const currentUser = computed(() => $store.getters.session.user);

const $http = useHttp();

const props = defineProps({
  modelValue: Boolean
});

const emit = defineEmits(['update:modelValue']);

const open = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
});

const defaultState = ref({
  query: '',
  steps: [],
  script: '',
  feedback: '',
  error: '',
  scriptResults: {},
  loading: false
});
const state = useSessionStorage('datasetGptState', defaultState.value);
const reset = () => (state.value = defaultState.value);

async function fetchSteps() {
  state.value.loading = true;
  try {
    const { data: response } = await $http.get('/dataset_gpt/steps', {
      params: {
        query: state.value.query
      }
    });
    state.value.steps = response.data;
  } catch (error) {
    state.value.error = error.message || 'Failed to fetch steps';
  } finally {
    state.value.loading = false;
  }
}

function addStep(index) {
  state.value.steps.splice(index + 1, 0, { task: '', table: null });
}

function deleteStep(index) {
  if (state.value.steps.length > 1) state.value.steps.splice(index, 1);
}

async function processSteps() {
  state.value.loading = true;
  state.value.script = '';
  try {
    const promises = state.value.steps.map(step =>
      $http.get(`/dataset_gpt/step`, {
        params: {
          step: step.task,
          table: step.table,
          priorScript: ''
        }
      })
    );
    const responses = await Promise.all(promises);
    responses.forEach((response, index) => {
      state.value.steps[index].code = response.data.data;
      state.value.script += response.data.data + '\n\n';
    });

    await refactorScript();
  } catch (error) {
    state.value.error = error.message || 'Unknown error during processing steps';
  } finally {
    state.value.loading = false;
  }
}

async function refactorScript() {
  state.value.loading = true;
  try {
    const { data: response } = await $http.post(`dataset_gpt/refactor`, {
      script: state.value.script,
      query: `
        <query>${state.value.query}</query>
        <error>${JSON.stringify(state.value.error)}</error>
        <feedback>${state.value.feedback}</feedback>
      `,
      steps: state.value.steps
    });
    state.value.script = response.data;
  } catch (error) {
    state.value.error = error.message || 'Unknown error during script refactoring';
  } finally {
    state.value.loading = false;
  }
}

async function processScript() {
  state.value.loading = true;
  try {
    const response = await $http.post('/custom_component_api', {
      mode: 'python',
      script: state.value.script
    });
    if (typeof response.data.data == 'string' && response.data.data.includes('Error')) {
      state.value.scriptResults = '';
      state.value.error = response.data.data?.split(':')[1]?.trim();
    } else {
      if (response.data.body && typeof response.data.body === 'string' && response.data.body.startsWith('Error')) {
        state.value.error = response.data.body.split(':')[1].trim();
        state.value.scriptResults = '';
      } else {
        state.value.scriptResults = response.data.body;
        state.value.error = null;
      }
    }
  } catch (error) {
    state.value.error = error.response.data.exception;
    state.value.scriptResults = {};
  } finally {
    state.value.loading = false;
  }
}

const datasets = ref([]);

watch(open, async () => {
  if (open.value) {
    const { data: response } = await $http.get(`dataset_gpt/catalog`);
    datasets.value = response.data.map(item => ({ id: item, label: item }));
  }
});

function openComponentBuilder() {
  localStorage.setItem('component-builder-source-script', state.value.script);
  localStorage.setItem('datasetLibraryModalState', false);
  window.open('/dashboard/component/new', '_blank', 'noopener=true');
}

const canSubmitTrainingData = computed(() => !!state.value.steps.every(step => step.task) && !!state.value.script);

async function addToTrainingData() {
  state.value.loading = true;
  try {
    await $http.post(`dataset_gpt/training`, {
      steps: state.value.steps,
      finalScript: state.value.script,
      query: state.value.query,
      user: currentUser.value.email
    });
  } catch (error) {
    state.value.error = error.message || 'Unknown error';
  } finally {
    state.value.loading = false;
  }
}
</script>
